import Link from "next/link";
import React from "react";
import dynamic from "next/dynamic";
import { HomePageQuery, NewsSummaryFragment } from "@graphql/generated/graphql";
import { Trans } from "@lingui/macro";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { Block as BlockProps } from "@components/news-stack/rich-content";

const CompactNewsCard = dynamic(() =>
  import("./rich-content/blocks/News/news-card").then(
    (mod) => mod.CompactNewsCard
  )
);

const Block = dynamic<{ data: BlockProps }>(() =>
  import("@components/news-stack/rich-content").then((mod) => mod.Block)
);

interface INewsStackPreviewCardsProps {
  newsStack: HomePageQuery["topExploreCategory"][0];
  newsCount?: 2 | 4;
}

interface INewsStackPreviewCardProps {
  previewBlock?: any;
  news?: NewsSummaryFragment;
}

const NewsStackPreviewCards: React.FunctionComponent<
  INewsStackPreviewCardsProps
> = ({ newsStack }) => {
  return (
    <>
      <div className="relative mb-4 flex w-full flex-col items-center">
        <div className="absolute -top-4 h-10 w-[90%] rounded-3xl bg-white shadow-custom"></div>
        <div className="z-10 w-full rounded-3xl bg-white py-6 px-4 shadow-custom sm:px-6 md:p-10">
          <div>
            <p className="pb-4 font-poppins text-xl font-bold 2xs:text-3xl">
              {newsStack.richContent?.data[0].title}
            </p>

            <div className="pointer-events-none h-[200px] overflow-hidden">
              {newsStack.richContent != null ? (
                <StackingCards
                  previewBlock={newsStack.richContent.data[0].content}
                />
              ) : (
                newsStack.news.map((news) => (
                  <div className="pb-4" key={news.id}>
                    <CompactNewsCard news={news} />
                  </div>
                ))
              )}
            </div>
            <div className="flex items-center space-x-2 md:mt-10">
              <div className="z-10 mt-4 flex-1 text-right font-sen text-sm text-primary2 underline">
                <Link href={`/news-stack/${newsStack.slug}`} passHref>
                  <a id="more-articles">
                    <Trans>More articles in this News Stack</Trans>
                  </a>
                </Link>
              </div>
              <ArrowRightIcon className="z-10 mt-4 h-4 w-4 text-primary2" />
            </div>
          </div>
          <div className="pointer-events-none absolute inset-0 top-[5%] bottom-14 bg-gradient-to-b from-white/0 to-white md:bottom-24 md:top-[10%]"></div>
        </div>
        <div className="absolute inset-0 top-[95%] z-10 h-[10%] w-full bg-white md:top-[90%] md:h-[15%]"></div>
      </div>
    </>
  );
};

const StackingCards: React.FunctionComponent<INewsStackPreviewCardProps> = ({
  previewBlock,
}) => {
  return (
    <div className="relative">
      {previewBlock.map((block) => (
        <div key={block.key}>
          <Block data={block} />
        </div>
      ))}
    </div>
  );
};

export default NewsStackPreviewCards;
